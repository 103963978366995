<template>
  <b-card no-body>
    <b-overlay :show="loading">
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col>
              <div>Ujian</div>
            </b-col>
            <b-col>
              <div align="right">
                <b-button
                  v-if="allowCreate()"
                  variant="outline-primary"
                  v-b-modal.modal-lg
                  class="btn-icon"
                  :to="{ path: 'ujian-admin/a' }"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button
                >
              </div>

              <b-modal
                v-model="ModalEdit"
                id="modal-tambah-sub"
                centered
                size="xl"
                :title="titleModal"
              >
                <b-card-text>
                  <b-form>
                    <b-row>
                      <b-col sm="12" md="4">
                        <b-form-group label="Nama Ujian" label-for="nama-paket">
                          <b-form-input
                            v-model="form.name"
                            placeholder="Nama Ujian"
                            name="nama-paket"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="4">
                        <b-form-group
                          label="Kategori Paket"
                          label-for="kategori"
                        >
                          <b-form-select
                            v-model="form.category_paket_id"
                            :options="optKatPaket"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="4">
                        <b-form-group label="Mode Paket" label-for="mode">
                          <b-form-select
                            v-model="form.mode"
                            :options="[
                              {
                                value: 'default',
                                text: 'Default',
                              },
                              { value: 'pauli', text: 'Pauli' },
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="4">
                        <b-form-group
                          label="Nama Ujian (singkat)"
                          label-for="harga"
                        >
                          <b-form-input
                            v-model="form.shortname"
                            name="shortname"
                            placeholder="shortname"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="4">
                        <b-form-group label="Acak Soal" label-for="acak">
                          <b-form-select
                            v-model="form.shuffle_question"
                            :options="optquestion"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="4">
                        <b-form-group
                          label="Tampilkan Hasil"
                          label-for="show_result"
                        >
                          <b-form-select
                            v-model="form.show_result"
                            :options="optresult"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="12">
                        <b-form-group label="Instruksi" label-for="instruksi">
                          <quill-editor
                            id="Konten"
                            v-model="form.instruction"
                            :options="editor_options"
                          >
                          </quill-editor>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="8" lg="6">
                        <b-form-group
                          label="Jenis Kalkulasi Skor Ujian"
                          label-for="type_score_calculation"
                        >
                          <b-form-select
                            v-model="form.type_score_calculation"
                            :options="typeScoreOptions"
                          />

                          <small class="my-1">
                            <strong
                              ><span class="text-danger">*</span>Jika ingin
                              membuat ujian Tes Kepribadian, pilih opsi
                              <span class="text-warning"
                                >"Berdasarkan Poin Opsi Soal"</span
                              ></strong
                            >
                          </small>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="Tambah"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click.prevent="tutupModal"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="3" class="">
              <label>Pilih Kategori Paket</label>
              <v-select
                label="text"
                v-model="filterKatPaket"
                class="select-size-sm w-full"
                placeholder="Pilih"
                @input="
                  getData();
                  getDataPaket();
                "
                :clearable="true"
                :options="optKatPaket"
              />
            </b-col>
            <b-col md="3" sm="3" class="" v-if="filterKatPaket">
              <label>Pilih Paket</label>
              <v-select
                label="text"
                v-model="filterPaket"
                class="select-size-sm w-full"
                @input="
                  getData();
                  getDataJadwal();
                "
                :clearable="true"
                :options="optPaket"
              />
            </b-col>
            <b-col md="3" sm="3" class="" v-if="filterPaket && filterKatPaket">
              <label>Pilih Jadwal</label>
              <v-select
                label="text"
                v-model="filterJadwal"
                class="select-size-sm w-full"
                @input="getData()"
                :clearable="true"
                :options="optJadwal"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3" sm="4" class="my-1" v-if="ujiandata.length >= 1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1" v-if="ujiandata.length >= 1">
              <b-form-group
                label="Sort"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-50"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-30"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Search......"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :fields="
                  filterKatPaket != null &&
                  filterPaket == null &&
                  filterJadwal == null
                    ? fieldsKat
                    : filterKatPaket != null &&
                      filterPaket != null &&
                      filterJadwal == null
                    ? fieldsPaket
                    : filterKatPaket != null &&
                      filterPaket != null &&
                      filterJadwal != null
                    ? fieldsJadwal
                    : ''
                "
                :items="ujiandata"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <div v-if="ujiandata.length == 1">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                          style="height: 100px"
                        />
                      </template>
                      <b-dropdown-item
                        :to="{
                          path: `ujian-admin/detail/${row.item.id}`,
                          query: {
                            category_paket_id: row.item.category_paket.id,
                            paket: row.item.paket ? row.item.paket.id : 0,
                            jadwal_id: row.item.jadwal ? row.item.jadwal.id : 0,
                            ujian_id: row.item.id,
                          },
                        }"
                      >
                        <feather-icon icon="SettingsIcon" class="mr-50" />
                        <span>Detail dan Pilih Soal</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="allowUpdate()"
                        @click="ModalUbah(row.item)"
                      >
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="allowDelete()"
                        @click="ModalHapus(row.item)"
                      >
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Hapus</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div v-if="ujiandata.length != 1">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item
                        :to="{
                          path: `ujian-admin/detail/${row.item.id}`,
                          query: {
                            category_paket_id: row.item.category_paket.id,
                            paket: row.item.paket ? row.item.paket.id : 0,
                            jadwal_id: row.item.jadwal ? row.item.jadwal.id : 0,
                            ujian_id: row.item.id,
                          },
                        }"
                      >
                        <feather-icon icon="SettingsIcon" class="mr-50" />
                        <span>Detail dan Pilih Soal</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="allowUpdate()"
                        @click="ModalUbah(row.item)"
                      >
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="allowDelete()"
                        @click="ModalHapus(row.item)"
                      >
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Hapus</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <!-- <b-button variant="outline-info" class="btn-icon" @click="ModalUbah(row.item)"> <feather-icon icon="EditIcon" /></b-button>
                  <b-button variant="outline-danger" class="btn-icon ml-1" @click="ModalHapus(row.item)"> <feather-icon icon="TrashIcon" /></b-button> -->
                </template>
                <!-- A custom formatted column -->
                <template #cell(name)="data">
                  {{ data.item.name }}
                  <br />
                  <b-badge variant="primary">
                    {{ data.item.shortname }}
                  </b-badge>
                </template>
                <template #cell(start_time)="data">
                  {{ humanDateTime(data.item.start_time) }}
                </template>
                <template #cell(end_time)="data">
                  {{ humanDateTime(data.item.end_time) }}
                </template>
                <template #cell(timer)="data">
                  {{ data.item.timer + " menit" }}
                </template>

                <!-- A virtual composite column -->
                <template #cell(sum_soal)="data">
                  {{ data.item.sum_soal >= 1 ? data.item.sum_soal : 0 }}
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>

              <b-card
                body-class="text-center"
                text-variant="danger"
                border-variant="danger"
                class="mt-1"
                v-if="ujiandata.length == 0 && filterKatPaket == null"
              >
                Silakan pilih kategori paket terlebih dahulu
              </b-card>
              <b-card
                body-class="text-center"
                text-variant="danger"
                border-variant="danger"
                class="mt-1"
                v-if="
                  filterJadwal == null &&
                  filterKatPaket != null &&
                  ujiandata.length == 0
                "
              >
                Ujian tidak ada, silakan pilih paket yang lainnya
              </b-card>
              <b-card
                body-class="text-center"
                text-variant="danger"
                border-variant="danger"
                class="mt-1"
                v-if="
                  filterJadwal != null &&
                  filterPaket != null &&
                  ujiandata.length == 0
                "
              >
                Ujian tidak ada, silakan pilih jadwal yang lainnya
              </b-card>
              <br />
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    quillEditor,
    vSelect,
    flatPickr,
    BDropdownItem,
    BDropdown,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      typeScoreOptions: [
        { value: "question_weight_based", text: "Berdasarkan Bobot Soal" },
        { value: "option_point_based", text: "Berdasarkan Poin Opsi Soal" },
      ],
      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      filterPaket: null,
      optPaket: [],
      filterKatPaket: null,
      optKatPaket: [],
      filterJadwal: null,
      optJadwal: [],
      id: null,
      activeAction: null,
      label: "Simpan",
      optquestion: [
        { value: "1", text: "Ya" },
        { value: "0", text: "Tidak" },
      ],
      optresult: [
        { value: "1", text: "Ya" },
        { value: "0", text: "Tidak" },
      ],
      //Data
      optmapel: [],
      optkatujian: [],
      ujiandata: [],
      form: {
        id: null,
        category_paket_id: null,
        name: null,
        shortname: null,
        show_result: null,
        instruction: null,
        shuffle_question: null,
        type_score_calculation: null,
      },
      //Modal
      titleModal: null,
      ModalEdit: false,
      //Table
      fieldsKat: [
        { key: "index", label: "No" },
        { key: "name", label: "Nama Ujian" },
        { key: "mode", label: "Mode Ujian" },
        { key: "category_paket.name", label: "Kategori Paket" },
        { key: "sum_soal", label: "Jumlah Soal" },
        { key: "action", label: "#" },
      ],
      fieldsPaket: [
        { key: "index", label: "No" },
        { key: "name", label: "Nama Ujian" },
        { key: "mode", label: "Mode Ujian" },
        { key: "category_paket.name", label: "Kategori Paket" },
        { key: "paket.name", label: "Paket" },
        { key: "sum_soal", label: "Jumlah Soal" },
        { key: "action", label: "#" },
      ],
      fieldsJadwal: [
        { key: "index", label: "No" },
        { key: "name", label: "Nama Ujian" },
        { key: "mode", label: "Mode Ujian" },
        // { key: "category_paket.name", label: "Kategori Paket" },
        { key: "paket.name", label: "Paket" },
        { key: "jadwal.name", label: "Jadwal" },
        { key: "sum_soal", label: "Jumlah Soal" },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optWarna: [
        { value: "primary", text: "primary" },
        { value: "info", text: "info" },
        { value: "secondary", text: "secondary" },
        { value: "warning", text: "warning" },
        { value: "success", text: "success" },
        { value: "light-primary", text: "light-primary" },
        { value: "light-info", text: "light-info" },
        { value: "light-secondary", text: "light-secondary" },
        { value: "light-warning", text: "light-warning" },
        { value: "light-success", text: "light-success" },
      ],
      optFilter: [
        { id: "name", value: "Kategori" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Kategori",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    filter(keyword) {
      if (keyword.length > 2 || keyword.length == 0) {
        this.getData();
      }
    },
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fieldsKat
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    isFormValid() {
      if (
        !this.form.category_paket_id ||
        !this.form.mode ||
        !this.form.shortname ||
        !this.form.name ||
        this.form.show_result == null ||
        this.form.shuffle_question == null ||
        !this.form.type_score_calculation
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    tutupModal() {
      this.ModalEdit = false;
      this.resetForm();
      this.getData();
    },
    resetForm() {
      this.form = {
        id: null,
        name: null,
        shortname: null,
        instruction: null,
        show_result: null,
        shuffle_question: null,
      };
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Ujian";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Ujian";
      this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 2; //soft delete
          this.$store
            .dispatch("adminujian/save", [data])
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (!this.isFormValid) {
        this.pesanGagal();
        return false;
      }
      let payload = {
        // type_ujian: this.form.type_ujian,
        user_id: this.user.id,
        category_paket_id: this.form.category_paket_id,
        mode: this.form.mode,
        name: this.form.name,
        shortname: this.form.shortname,
        soal_ids: this.form.soal_ids,
        show_result: this.form.show_result,
        shuffle_question: this.form.shuffle_question,
        instruction: this.form.instruction,
        type_score_calculation: this.form.type_score_calculation,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("adminujian/save", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.resetForm();
          this.ModalEdit = false;
          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Ujian berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getData() {
      this.loading = true;
      if (this.filterKatPaket != null) {
        if (this.filterPaket == null) {
          this.filterJadwal = null;
        }
        const perPage = parseInt(this.perPage);
        const currentPage = (parseInt(this.currentPage) - 1) * perPage;
        const payload = {
          filter_by: "name",
          search: null,
          order: "desc",
          start: currentPage,
          length: this.perPage,
          category_paket_id:
            this.filterKatPaket != null ? this.filterKatPaket.id : null,
          paket_id: this.filterPaket != null ? this.filterPaket.id : null,
          jadwal_id: this.filterJadwal != null ? this.filterJadwal.id : null,
          user_id: this.user.id,
        };

        // chgeck jika ada search, maka start nya jadi 0 lagi
        const keyword =
          this.filter != null && this.filter.length > 2 ? this.filter : null;
        if (keyword) {
          payload.search = keyword;
        }
        this.$store
          .dispatch("adminujian/index", payload)
          .then((response) => {
            this.loading = false;
            const { data, totalRecords } = response.data;
            // let ujiandata = response.data.data;
            this.ujiandata = data;
            this.totalRows = totalRecords;
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("errorData", error);
          });
      } else {
        this.ujiandata = "";
        this.filterJadwal = "";
        this.filterPaket = "";
        this.loading = false;
      }
    },
    async getDataKategoriPaket() {
      const payload = {};
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then(async (response) => {
          let optKatPaket = response.data.data;
          optKatPaket.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optKatPaket = optKatPaket;
          if (this.$route.query?.c) {
            const categoryId = this.$route.query?.c;
            const categoryPaket = this.optKatPaket.find(
              (item) => item.id == categoryId
            );
            if (categoryPaket) {
              this.filterKatPaket = categoryPaket;
              await this.getData();
              this.getDataPaket();
            }
          }
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getDataPaket() {
      const payload = {
        category_id:
          this.filterKatPaket != null ? this.filterKatPaket.id : null,
      };
      this.$store
        .dispatch("paketTryout/index", payload)
        .then((response) => {
          let optPaket = response.data.data;
          optPaket.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optPaket = optPaket;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getDataJadwal() {
      const payload = {
        category_paket_id:
          this.filterKatPaket != null ? this.filterKatPaket.id : null,
        paket_id: this.filterPaket != null ? this.filterPaket.value : null,
      };
      this.$store
        .dispatch("jadwaladmin/index", payload)
        .then((response) => {
          let optJadwal = response.data.data;
          optJadwal.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optJadwal = optJadwal;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  async mounted() {
    this.getDataKategoriPaket();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
